<style lang="scss">
.druckstrecke {
  .tabelle-box {
    .col-12 {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
}
</style>

<template>
  <div class="druckstrecke content container">
    <h1>Ihr Mitdruck in der Kleinen Zeitung.</h1>
    <h2>Mittendrin, statt nur dabei!</h2>
    <p class="intro-txt">Sichern Sie sich maximale Aufmerksamkeit in einem glaubwürdigen Umfeld. Ihre Werbebotschaft wird großflächig und auffällig als Bestandteil der Kleinen Zeitung dargestellt.</p>

    <h2>Ihre Vorteile</h2>
    <ul class="content-ul">
      <li>kürzere Vorlaufzeiten</li>
      <li>keine Beilegekosten</li>
      <li>Möglichkeit der professionellen Gestaltung Ihres Mitdrucks durch unser <router-link :to="{ name: 'NativeContent' }" class="content-link">Content Service</router-link></li>
    </ul>
    <div class="margin-tb-s"></div>

    <h2>Ihre Werbemöglichkeiten</h2>
    <!--<div class="row image-box justify-content-center align-self-center">
      <div class="col-lg-8 ">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/druckstrecke-v1.png" alt="Druckstrecke" class="img-fluid" />
      </div>
    </div>-->
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Steiermark
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left">DI - DO</div>
            <div class="col-lg-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">8 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">40.818,92</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">12 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">42.224,87</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">16 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">45.169,92</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Kärnten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left">DI - DO</div>
            <div class="col-lg-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">8 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">21.063,80</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">12 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">21.774,11</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">16 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">23.247,36</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            KOMBI
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left">DI - DO</div>
            <div class="col-lg-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">8 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">60.237,30</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">12 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">62.327,03</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-6 col-12 text-align-left">16 Seiten</div>
            <div class="col-6 text-align-left visible-small">DI - DO</div>
            <div class="col-lg-6 col-6 text-align-right">66.718,80</div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 text-center">
        <div class="info-txt-tabelle">Mitdruck-Tarife sind Fixpreise, keine weiteren Rabatte mehr möglich.</div>
      </div>
    </div>
    <div class="row justify-content-center hinweis-box height-100px margin-t-l margin-b-m">
      <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
      <div class="col-lg-10 bg-right align-self-center">
        <p>Ihre Werbebotschaft braucht mehr Platz? Buchen Sie eine Beilage und lassen Sie dieses von unserem Content-Service-Team professionell gestalten! <router-link :to="{ name: 'NativeContent' }" class="content-link">Mehr zu Content Service</router-link></p>
      </div>
    </div>
    <h2>Überdruck</h2>
    <p>Überdruck eines Mitdrucks ist auf Anfrage möglich.</p>
    <div class="row justify-content-center align-self-center">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            KOSTEN ÜBERDRUCK
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left"></div>
            <div class="col-lg-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-6 col-6 text-align-left">bis 500 Stück</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">kostenlos</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-6 col-6 text-align-left">bis 3.000 Stück</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">350,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-6 col-6 text-align-left justify-content-center align-self-center">über 3.000 Stück</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">Verrechnung als Vordruck gemäß Druckkostenkatalog</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center hinweis-box height-100px margin-tb-s">
      <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
      <div class="col-lg-10 bg-right align-self-center"><p>Bitte beachten Sie ein vorbehaltliches Schieberecht von +/- 2 Tagen.</p></div>
    </div>

    <div class="info-txt-preis">
      Alle Preise verstehen sich in Euro inklusive Druckkosten, exklusive PR-Produktionskosten, 5% Werbeabgabe und 20% Umsatzsteuer.<br />
      Die Kleine Zeitung behält sich ein Schieberecht von zwei Tagen vor, sollte es aus produktionstechnischen Gründen notwendig sein. Bei Kosten für den Überdruck keine Rabattierung möglich.
    </div>
    <br /><br />
    <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
    <div class="row umsetzungs-bsp">
      <div class="col-lg-6 col-md-6">
        <a href="../../pdf/beilagen/01-12-WS-Journal.pdf" target="_blank">
          <img src="@/assets/img/img-content/beilagensonderwerbeformen/01-12-WS-Journal.jpg" alt="WS Journal" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-6 col-md-6">
        <a href="../../pdf/beilagen/01-12-Timberra.pdf" target="_blank">
          <img src="@/assets/img/img-content/beilagensonderwerbeformen/01-12-Timberra.jpg" alt="Timberra" class="img-fluid shadow" />
        </a>
      </div>
    </div>
    <!-- -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';

export default defineComponent({
  name: 'Mitdruck',
  components: {
    //Modal,
  },
});
</script>
